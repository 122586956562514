import React, { memo } from 'react';
import { css } from '@emotion/core';
import { Field } from 'formik';
import { colors } from '../../utils/variables';
import ErrorMessage from './ErrorMessage';

const TextareaInput = ({ placeholder, name, style, error, touched }) => {
  return (
    <div css={baseCSS}>
      <Field
        component="textarea"
        css={css`
        ${inputCSS}
        ${style}
        border: 2px ${touched && error ? colors.red : colors.black} solid;
      `}
        name={name}
        placeholder={placeholder}
        rows={8}
      />
      <ErrorMessage visible={touched && error} error={error} />
    </div>
  );
};

export default memo(TextareaInput);

const baseCSS = css`
  text-align: left;
`;

const inputCSS = css`
  align-items: center;
  background: #fff;
  border: 2px ${colors.black} solid;
  border-radius: 1.25rem;
  color: ${colors.black};
  display: flex;
  font-family: 'Dosis';
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  outline: none;
  padding: 0.75rem 1.25rem;
  width: 100%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #9d9d9d;
    opacity: 1;
  }

  &:focus {
    border: 2px ${colors.blue} solid;
  }
`;
