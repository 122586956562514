import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Layout from '../components/shared/Layout';
import SecondaryPageWrapper from '../components/styled/SecondaryPageWrapper';
import TopBanner from '../components/shared/TopBanner';
import Container from '../components/styled/Container';
import { CopyHeading, BodyText } from '../components/styled/Typography';
import ContactForm from '../components/shared/ContactForm';
import { viewports } from '../utils/variables';

const ContactPage = ({ data }) => {
  const {
    wordpressPage: { acf }
  } = data;
  return (
    <Layout theme="secondary" slug="contact">
      <SecondaryPageWrapper>
        <TopBanner heading={acf.banner_heading} image={acf.banner_image} />
        <Container theme="secondary" style={containerCSS}>
          <div css={columnCSS}>
            <CopyHeading theme="large">{acf.contact_heading}</CopyHeading>
            <BodyText theme="large">{acf.contact_copy}</BodyText>
            <div css={dividerCSS} />
            {/* <CopyHeading theme="large">{acf.address_heading}</CopyHeading> */}
            {/* <BodyText theme="large">{acf.address_copy}</BodyText> */}
          </div>
          <div css={columnCSS}>
            <ContactForm heading={acf.form_heading} />
          </div>
        </Container>
      </SecondaryPageWrapper>
    </Layout>
  );
};

export default memo(ContactPage);

const containerCSS = css`
  justify-content: space-between;
  padding: 3.125rem 0 6.25rem;

  @media ${viewports.medium} {
    display: flex;
  }
`;

const columnCSS = css`
  @media ${viewports.medium} {
    width: 48%;
  }
`;

const dividerCSS = css`
  background-color: #d8d8d8;
  height: 1px;
  margin: 3.125rem 0 1.125rem;

  @media ${viewports.medium} {
    width: 65%;
  }
`;

export const query = graphql`
  query contactQuery($page_id: Int = 77) {
    wordpressPage(wordpress_id: { eq: $page_id }) {
      title
      acf {
        banner_heading
        banner_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        contact_heading
        contact_copy
        address_heading
        address_copy
        form_heading
      }
    }
  }
`;
