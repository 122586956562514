import React, { memo } from 'react';
import { css } from '@emotion/core';
import { Field } from 'formik';
import { colors } from '../../utils/variables';
import ErrorMessage from './ErrorMessage';

const TextInput = ({ placeholder, name, style, error, touched }) => {
  return (
    <div css={baseCSS}>
      <Field
        component="input"
        css={css`
          ${inputCSS}
          ${style}
          border: 2px ${touched && error ? colors.red : colors.black} solid;
        `}
        type="text"
        name={name}
        placeholder={placeholder}
      />
      <ErrorMessage visible={touched && error} error={error} />
    </div>
  );
};

export default memo(TextInput);

const baseCSS = css`
  text-align: left;
`;

const inputCSS = css`
  align-items: center;
  background: #fff;
  border-radius: 1.25rem;
  color: ${colors.black};
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 2.5rem;
  justify-content: center;
  outline: none;
  padding: 0 1.25rem 0 1rem;
  width: 100%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #9d9d9d;
    opacity: 1;
  }

  &:focus {
    border: 2px ${colors.blue} solid;
  }
`;
