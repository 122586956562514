import React, { memo } from 'react';
import { css } from '@emotion/core';
import { useTransition, animated } from 'react-spring';
import { colors } from '../../utils/variables';

const SuccessMessage = ({ visible, message, className }) => {
  const transition = useTransition(visible, null, {
    from: { h: 0, o: 0 },
    enter: { h: 18, o: 1 },
    leave: { h: 0, o: 0 }
  });

  return transition.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          style={{
            height: props.h.interpolate(h => `${h}px`),
            opacity: props.o.interpolate(o => o)
          }}
        >
          <div css={baseCSS} className={className}>
            {message}
          </div>
        </animated.div>
      )
  );
};

export default memo(SuccessMessage);

const baseCSS = css`
  color: ${colors.green};
  font-size: 1rem;
  line-height: 125%;
  overflow: hidden;
  margin-top: 0.25rem;
  position: absolute;
  width: 100%;
`;
