import React, { memo } from 'react';
import { css } from '@emotion/core';
import { colors, viewports } from '../../utils/variables';
import Loader from '../shared/Loader';

const SubmitButton = ({ children, isSubmitting, style }) => {
  return (
    <button
      css={css`
        ${baseCSS}
        ${style}

        @media ${viewports.medium} {
          &:hover {
            background-color: ${colors.darkBlue};
            border-color: ${colors.darkBlue};
          }
        }
      `}
      type="submit"
    >
      {!isSubmitting ? children : <Loader />}
    </button>
  );
};

export default memo(SubmitButton);

const baseCSS = css`
  align-items: center;
  background: ${colors.blue};
  border: 0;
  border-radius: 1.25rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: 'Dosis';
  font-weight: 700;
  height: 2.5rem;
  justify-content: center;
  letter-spacing: 0.02em;
  outline: none;
  text-decoration: none;
  width: 8.75rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
`;
