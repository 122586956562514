import React, { memo, useState } from 'react';
import { css } from '@emotion/core';
import validator from 'validator';
import { Formik, Form } from 'formik';
import axios from 'axios';
import { CopyHeading } from '../styled/Typography';
import TextInput from '../inputs/TextInput';
import TextareaInput from '../inputs/TextareaInput';
import SubmitButton from '../styled/SubmitButton';
import SuccessMessage from '../inputs/SuccessMessage';

const ContactForm = ({ heading }) => {
  const [successMessage, setSuccessMessage] = useState(undefined);

  const validate = values => {
    const errors = {};
    if (!values.name) errors.name = 'Required';
    if (!validator.isEmail(values.email))
      errors.email = 'Invalid email address';
    if (!values.email) errors.email = 'Required';
    if (!values.subject) errors.subject = 'Required';
    if (!values.message) errors.message = 'Required';
    return errors;
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        subject: '',
        message: ''
      }}
      validate={validate}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);

        const { data } = await axios.post(
          'https://formcarry.com/s/O6rnbKUYK7r',
          values,
          { headers: { Accept: 'application/json' } }
        );

        actions.setSubmitting(false);
        const { title, message } = data;
        setSuccessMessage(`${title} ${message}`);
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <>
          <Form>
            {!successMessage && (
              <>
                <CopyHeading theme="large">{heading}</CopyHeading>
                <TextInput
                  name="name"
                  placeholder="Enter name"
                  style={inputCSS}
                  error={errors.name}
                  touched={touched.name}
                />
                <TextInput
                  name="email"
                  placeholder="Enter email"
                  style={inputCSS}
                  error={errors.email}
                  touched={touched.email}
                />
                <TextInput
                  name="subject"
                  placeholder="Enter subject"
                  style={inputCSS}
                  error={errors.subject}
                  touched={touched.subject}
                />
                <TextareaInput
                  name="message"
                  placeholder="Enter your message"
                  style={inputCSS}
                  error={errors.message}
                  touched={touched.message}
                />
                <input type="hidden" name="_gotcha" />
                <SubmitButton style={inputCSS} isSubmitting={isSubmitting}>
                  SUBMIT
                </SubmitButton>
              </>
            )}
            {successMessage && (
              <SuccessMessage message={successMessage} visible />
            )}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default memo(ContactForm);

const inputCSS = css`
  margin-top: 1.25rem;
`;
