import React, { memo } from 'react';
import { css } from '@emotion/core';
import { useTransition, animated } from 'react-spring';
import { colors } from '../../utils/variables';

const ErrorMessage = ({ visible, error, className }) => {
  const transition = useTransition(visible, null, {
    from: { h: 0, o: 0 },
    enter: { h: 18, o: 1 },
    leave: { h: 0, o: 0 }
  });

  return transition.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          style={{
            height: props.h.interpolate(h => `${h}px`),
            opacity: props.o.interpolate(o => o)
          }}
        >
          <div css={baseCSS} className={className}>
            {error}
          </div>
        </animated.div>
      )
  );
};

export default memo(ErrorMessage);

const baseCSS = css`
  color: ${colors.red};
  font-size: 0.75rem;
  line-height: 125%;
  overflow: hidden;
  margin-top: 0.25rem;
  position: absolute;
`;
