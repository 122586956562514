import React, { memo } from 'react';
import { css, keyframes } from '@emotion/core';

const Loader = () => {
  return (
    <span css={baseCSS}>
      <span css={elCSS} />
      <span css={elCSS} />
      <span css={elCSS} />
      <span css={elCSS} />
    </span>
  );
};

export default memo(Loader);

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const baseCSS = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const elCSS = css`
  animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 3px solid #fff;
  border-radius: 50%;
  border-color: #fff transparent transparent transparent;
  height: 1.5rem;
  position: absolute;
  width: 1.5rem;

  &:nth-of-type(1) {
    animation-delay: -0.45s;
  }

  &:nth-of-type(2) {
    animation-delay: -0.3s;
  }

  &:nth-of-type(3) {
    animation-delay: -0.15s;
  }
`;
